import { useFormik } from "formik"
import { Link, useHistory, useParams } from "react-router-dom"
import Template from "./template.component"
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { deleteResource, getResource, saveResource, updateResource } from "../services/resource.service";
import SpinnerComponent from "./spinner-component";

const ResourceComponent = (): JSX.Element => {

  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(!!id)

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      title: '',
      url: '',
      category: ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Name is required.'),
      url: Yup.string().required('URL is required.'),
      category: Yup.string().required('Category is required.')
    }),
    onSubmit: async resource => {
      setIsLoading(true)
      
      try {
        if (id) {
          const msg = await updateResource(resource)
        } else {
          const msg = await saveResource(resource)
        }
        history.push('/resources')
      } catch (e: any) {

      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    const init = async () => {
      try {
        const resource = await getResource(id)
        formik.setValues(resource)
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      init()
    }
  }, [])

  return (
    <Template title="Resource | Gee Linsky Portal" description="Resource for Gee Linsky portal.">
      <>
        {isLoading && <SpinnerComponent />}
        {
          !isLoading &&
          <>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/resources">Resources</Link></li>
                <li className="breadcrumb-item active">{formik.values.title}</li>
              </ol>
            </nav>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.title && formik.errors.title,
                      'is-valid': formik.touched.title && !formik.errors.title
                    }
                  )}
                  {...formik.getFieldProps('title')}
                />
                {formik.touched.title && formik.errors.title && <div className="invalid-feedback">{formik.errors.title}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">URL</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.url && formik.errors.url,
                      'is-valid': formik.touched.url && !formik.errors.url
                    }
                  )}
                  {...formik.getFieldProps('url')}
                />
                {formik.touched.url && formik.errors.url && <div className="invalid-feedback">{formik.errors.url}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">Category</label>
                <select 
                  className={classNames(
                    'form-select', {
                      'is-invalid': formik.touched.category && formik.errors.category,
                      'is-valid': formik.touched.category && !formik.errors.category
                    }
                  )}
                  {...formik.getFieldProps('category')}
                >
                  <option hidden></option>
                  <option>Technology</option>
                  <option>Finance</option>
                  <option>Health</option>
                </select>
                {formik.touched.category && formik.errors.category && <div className="invalid-feedback">{formik.errors.category}</div>}
              </div>
              <div className="d-flex align-content-start flex-wrap">
                <button type="submit" className={classNames('btn me-2', { 'btn-success': !id, 'btn-primary': id })} disabled={isLoading}>{id ? 'Update' : 'Create'}</button>
                {
                  id &&
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isLoading}
                    onClick={async () => {
                      const msg = await deleteResource(id)
                      history.push('/resources')
                    }}
                  >
                    Delete
                  </button>  
                }
              </div>
            </form>
          </>
        }
      </>
    </Template>
  )
}

export default ResourceComponent
