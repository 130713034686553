import { useEffect } from "react"
import { Helmet } from 'react-helmet'

import Navbar from "./navbar.component"

type Props = {
  title: string
  description: string
  children: React.ReactChild
}

const Template = (props: Props): JSX.Element => {

  useEffect((): void => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <Navbar fixedTop={false} />
      <div className="container py-5">
        {props.children}
      </div>
    </>
  )
}

export default Template
