import { createContext, useContext } from 'react'

export type GlobalState = {
  isLoggedIn: boolean
  setIsLoggedIn: (isLoggedIn: boolean) => void
}

export const GlobalStateContext = createContext<GlobalState>({
  isLoggedIn: false,
  setIsLoggedIn: () => {}
})

export const useGlobalState = () => useContext<GlobalState>(GlobalStateContext)
