import Asset from '../models/asset'
import AssetModel from '../models/asset.model'
import PortfolioDetail from '../models/portfolio-detail'
import PortfolioSummary from '../models/portfolio-summary'
import enviroment from '../util/enviroment'
import { response } from './fetch.service'

export const getPortfolio = (id: string): Promise<PortfolioDetail> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${id}`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const getPortfolioSummary = (id: string): Promise<PortfolioSummary> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${id}/summary`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const getPortfolios = (): Promise<PortfolioSummary[]> => {
  const url: string = `${enviroment.apiUrl}/portfolio`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const savePortfolio = (portfolio: PortfolioSummary): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(portfolio)
  }
  return response(fetch(url, options))
}

export const updatePortfolio = (portfolio: PortfolioSummary): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio`
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(portfolio)
  }
  return response(fetch(url, options))
}

export const deletePortfolio = (id: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
  }
  return response(fetch(url, options))
}

export const getAsset = (portfolioId: string, assetId: string): Promise<AssetModel> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${portfolioId}/asset/${assetId}`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const saveAsset = (asset: AssetModel, portfolioId: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${portfolioId}/asset`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(asset)
  }
  return response(fetch(url, options))
}

export const updateAsset = (asset: AssetModel, portfolioId: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${portfolioId}/asset`
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(asset)
  }
  return response(fetch(url, options))
}

export const deleteAsset = (portfolioId: string, assetId: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/portfolio/${portfolioId}/asset/${assetId}`
  const options = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
  }
  return response(fetch(url, options))
}