import { useFormik } from "formik"
import { Link, useHistory, useParams } from "react-router-dom"
import Template from "./template.component"
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { deleteResource, getResource, saveResource, updateResource } from "../services/resource.service";
import SpinnerComponent from "./spinner-component";
import { deletePortfolio, getPortfolio, savePortfolio, updatePortfolio } from "../services/portfolio.service";
import PortfolioDetail from "../models/portfolio-detail";
import { currencyFormat } from "../util/format";

const PortfolioComponent = (): JSX.Element => {

  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(!!id)
  const [portfolio, setPortfolio] = useState<PortfolioDetail>()

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.'),
    }),
    onSubmit: async portfolio => {
      setIsLoading(true)
      
      try {
        if (id) {
          const msg = await updatePortfolio({ name: portfolio.name, _id: id })
        } else {
          const msg = await savePortfolio(portfolio)
        }
        history.push('/portfolios')
      } catch (e: any) {

      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    const init = async () => {
      try {
        const portfolio = await getPortfolio(id)
        formik.setValues({ name: portfolio.name })
        setPortfolio(portfolio)
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      init()
    }
  }, [])

  return (
    <Template title="Portfolio | Gee Linsky Portal" description="Portfolio for Gee Linsky portal.">
      <>
        {isLoading && <SpinnerComponent />}
        {
          !isLoading &&
          <>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/portfolios">Portfolios</Link></li>
                <li className="breadcrumb-item active">{formik.values.name}</li>
              </ol>
            </nav>
            {
              id &&
              <>
                <Link to={`/portfolio/${id}/asset`} className="btn btn-success mb-1">Create</Link>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Ticker</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Percent</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        portfolio?.assets.map(asset => (
                          <tr key={asset._id}>
                            <td><Link to={`/portfolio/${id}/asset/${asset._id}`}>{asset.name}</Link></td>
                            <td>{asset.ticker}</td>
                            <td>{currencyFormat(asset.price)}</td>
                            <td>{asset.quantity}</td>
                            <td>{`${asset.percent}%`}</td>
                            <td>{currencyFormat(asset.total)}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={5}></td>
                        <td>{currencyFormat(portfolio?.total)}              
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </>
            }
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.name && formik.errors.name,
                      'is-valid': formik.touched.name && !formik.errors.name
                    }
                  )}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
              </div>
              <div className="d-flex align-content-start flex-wrap">
                <button type="submit" className={classNames('btn me-2', { 'btn-success': !id, 'btn-primary': id })} disabled={isLoading}>{id ? 'Update' : 'Create'}</button>
                {
                  id &&
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isLoading}
                    onClick={async () => {
                      const msg = await deletePortfolio(id)
                      history.push('/portfolios')
                    }}
                  >
                    Delete
                  </button>  
                }
              </div>
            </form>
          </>
        }
      </>
    </Template>
  )
}

export default PortfolioComponent
