import { Helmet } from 'react-helmet'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import classNames from 'classnames';
import { signIn } from '../services/user.service';
import { useState } from 'react';
import Alert from '../models/alert';
import AlertComponent from './alert.component';
import { useGlobalState } from '../util/global-state';

const SignIn = (): JSX.Element => {

  const [alert, setAlert] = useState<Alert>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { setIsLoggedIn } = useGlobalState()

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required.'),
      password: Yup.string().required('Password is required.')
    }),
    onSubmit: async (values, options) => {
      setIsLoading(true)
      
      try {
        const jwt = await signIn(values)
        window.localStorage.setItem('JWT', jwt)
        setIsLoggedIn(true)
      } catch (e: any) {
        setAlert({ message: e.message, color: 'danger' })
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <>
      <Helmet>
        <title>Sign In | Gee Linsky Portal</title>
        <meta name="description" content="Sign in Gee Linsky." />
      </Helmet>
      
      <div className="container py-5">
        <img src="https://static.geelinsky.com/g-key.png" className="mx-auto d-block pb-3" />
        
        <div style={{ 'maxWidth': '400px' }} className="mx-auto d-block">
          <AlertComponent alert={alert} />
        </div>
        
        <form
          onSubmit={formik.handleSubmit}
          style={{ 'maxWidth': '400px' }}
          className="mx-auto d-block"
        >
          <div className="mb-3">
            <label className="form-label">Username</label>
            <input
              type="text"
              className={classNames(
                'form-control', {
                  'is-invalid': formik.touched.username && formik.errors.username,
                  'is-valid': formik.touched.username && !formik.errors.username
                }
              )}
              {...formik.getFieldProps('username')}
            />
            {formik.touched.username && formik.errors.username && <div className="invalid-feedback">{formik.errors.username}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className={classNames(
                'form-control', {
                  'is-invalid': formik.touched.password && formik.errors.password,
                  'is-valid': formik.touched.password && !formik.errors.password
                }
              )}
              {...formik.getFieldProps('password')}
            />
            {formik.touched.password && formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>}
          </div>
          <button type="submit" className="btn btn-primary" disabled={isLoading}>Sign In</button>
        </form>

      </div>
    </>
  )
}

export default SignIn
