export async function response<T>(fetch: Promise<Response>): Promise<T> {
  const res: Response = await fetch
  const json: any = await res.json()

  if (res.ok) {
    return json
  } else {
    throw Error(json)
  }
}
