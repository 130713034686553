import { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useGlobalState } from '../util/global-state'

type Props = {
  fixedTop: boolean
}

const Navbar = (props: Props): JSX.Element => {
  const navItems: { to: string, label: string }[] = [
    { to: '/resources', label: 'Resources' },
    { to: '/portfolios', label: 'Portfolios' },
    { to: '/docs', label: 'Docs' },
  ]

  const [show, setShow] = useState<boolean>(false)

  const history = useHistory()

  const { setIsLoggedIn } = useGlobalState()

  const onNavLinkClick = (): void => {
    if (show) {
      setShow(!show)
    }
  }

  return (
    <nav className={classNames('navbar navbar-expand-lg navbar-light bg-light border-bottom', { 'fixed-top': props.fixedTop })}>
      <div className="container">
        <NavLink
          className="navbar-brand"
          to="/"
          onClick={onNavLinkClick}
        >
          <img src="https://static.geelinsky.com/g-key.png" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          onClick={(): void => setShow(!show)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={classNames('collapse navbar-collapse', { show })}>
          <ul className="navbar-nav">
            {
              navItems.map((navItem, i) => (
                <li className="nav-item" key={i}>
                  <NavLink className="nav-link" to={navItem.to} onClick={onNavLinkClick}>
                    {navItem.label}
                  </NavLink>
                </li>
              ))
            }
          </ul>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a
                type='button'
                className='link-secondary'
                onClick={() => {
                  window.localStorage.removeItem('JWT')
                  setIsLoggedIn(false)
                  history.push('/')
                }}
              >
                <i className='fas fa-sign-out-alt' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
