import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DocSummaryModel from "../models/doc-summary.model"
import ResourceModel from "../models/resource"
import { getDocs } from "../services/doc.service"
import { getResources } from "../services/resource.service"
import Spinner from "./spinner-component"
import Template from "./template.component"

const DocsComponent = (): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [docs, setDocs] = useState<DocSummaryModel[]>([])

  useEffect((): void => {
    const init = async () => {
      try {
        const docs = await getDocs()
        setDocs(docs)
      } catch (e) {
        
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Docs | Gee Linsky Portal" description="Docs for Gee Linsky portal.">
     <>
      {isLoading && <Spinner />}
      {
        !isLoading &&
        <>
          <Link to="/doc" className="btn btn-success mb-1">Create</Link>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Public</th>
                </tr>
              </thead>
              <tbody>
                {docs.map(doc => (
                  <tr key={doc._id}>
                    <td><Link to={`/doc/${doc._id}`}>{doc.name}</Link></td>
                    <td>{doc.category}</td>
                    <td>{doc.public ? '🟢' : '🔴'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      }
     </>
    </Template>
  )
}

export default DocsComponent
