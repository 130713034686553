import { useFormik } from "formik"
import { Link, useHistory, useParams } from "react-router-dom"
import Template from "./template.component"
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { deleteResource, getResource, saveResource, updateResource } from "../services/resource.service";
import SpinnerComponent from "./spinner-component";
import Markdown from './Markdown'
import { deleteDoc, getDoc, saveDoc, updateDoc } from "../services/doc.service";

const DocComponent = (): JSX.Element => {

  const tabs = ['Preview', 'Edit']

  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(!!id)
  const [activeTab, setActiveTab] = useState<number>(0)

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      name: '',
      content: '',
      category: '',
      public: false
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.'),
      content: Yup.string().required('Content is required.'),
      category: Yup.string().required('Category is required.'),
      // public: Yup.boolean().required('Public is required.')
    }),
    onSubmit: async doc => {
      setIsLoading(true)

      try {
        if (id) {
          const msg = await updateDoc(doc)
        } else {
          const msg = await saveDoc(doc)
        }
        history.push('/docs')
      } catch (e: any) {

      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    const init = async () => {
      try {
        const doc = await getDoc(id)
        console.log(doc.public)
        formik.setValues(doc)
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      init()
    }
  }, [])

  return (
    <Template title="Doc | Gee Linsky Portal" description="Doc for Gee Linsky portal.">
      <>
        {isLoading && <SpinnerComponent />}
        {
          !isLoading &&
          <>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/docs">Docs</Link></li>
                <li className="breadcrumb-item active">{formik.values.name}</li>
              </ol>
            </nav>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.name && formik.errors.name,
                      'is-valid': formik.touched.name && !formik.errors.name
                    }
                  )}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">Category</label>
                <select 
                  className={classNames(
                    'form-select', {
                      'is-invalid': formik.touched.category && formik.errors.category,
                      'is-valid': formik.touched.category && !formik.errors.category
                    }
                  )}
                  {...formik.getFieldProps('category')}
                >
                  <option hidden></option>
                  <option>Technology</option>
                  <option>Finance</option>
                  <option>Health</option>
                </select>
                {formik.touched.category && formik.errors.category && <div className="invalid-feedback">{formik.errors.category}</div>}
              </div>
              <div className="mb-3 form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...formik.getFieldProps('public')}
                  defaultChecked={formik.values.public}
                />
                <label className="form-check-label">Public</label>
              </div>
              <div className="d-flex align-content-start flex-wrap">
                <button type="submit" className={classNames('btn me-2', { 'btn-success': !id, 'btn-primary': id })} disabled={isLoading}>{id ? 'Update' : 'Create'}</button>
                {
                  id &&
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isLoading}
                    onClick={async () => {
                      const msg = await deleteDoc(id)
                      history.push('/docs')
                    }}
                  >
                    Delete
                  </button>  
                }
              </div>
              <ul className="nav nav-tabs mt-4 mb-2">
                {
                  tabs.map((tab: string, i: number) => (
                    <li className="nav-item">
                      <button
                        className={classNames('nav-link', { active: i === activeTab })}
                        onClick={(): void => setActiveTab(i)}
                        type="button"
                      >
                        {tab}
                      </button>
                    </li>
                  ))
                }
              </ul>
              {
                activeTab === 0 &&
                <Markdown source={formik.values.content} />
              }
              {
                activeTab === 1 &&
                <textarea
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.content && formik.errors.content,
                      'is-valid': formik.touched.content && !formik.errors.content
                    }
                  )}
                  {...formik.getFieldProps('content')}
                  rows={formik.values.content.split('\n').length}
                />
              }
            </form>
          </>
        }
      </>
    </Template>
  )
}

export default DocComponent
