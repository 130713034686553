import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'

type CodeBlockProps = {
  language: string
  values: string
}

const CodeBlock = (props: CodeBlockProps) => (
  <Prism
    language={props.language}
    style={duotoneLight}
  >
    {props.values}
  </Prism>
)

type MarkdownProps = {
  source: any
}

export default (props: MarkdownProps) => (
  <ReactMarkdown
    source={props.source}
    linkTarget="_blank"
    // renderers={{ code: CodeBlock }}
  />
)
