import DocSummaryModel from '../models/doc-summary.model'
import DocModel from '../models/doc.model'
import ResourceModel from '../models/resource'
import enviroment from '../util/enviroment'
import { response } from './fetch.service'

export const getDoc = (id: string): Promise<DocModel> => {
  const url: string = `${enviroment.apiUrl}/doc/${id}`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const getDocs = (): Promise<DocSummaryModel[]> => {
  const url: string = `${enviroment.apiUrl}/doc`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const saveDoc = (docModel: DocModel): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/doc`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(docModel)
  }
  return response(fetch(url, options))
}

export const updateDoc = (docModel: DocModel): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/doc`
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(docModel)
  }
  return response(fetch(url, options))
}

export const deleteDoc = (id: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/doc/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
  }
  return response(fetch(url, options))
}
