import SignIn from '../models/sign-in.model'
import env from '../util/enviroment'
import { response } from './fetch.service'

export const signIn = async (signIn: SignIn): Promise<string> => {
  const url: string = `${env.apiUrl}/user/login`
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(signIn)
  }

  return response(fetch(url, options))
}
