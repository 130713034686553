import { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './components/home.component'
import { GlobalStateContext } from './util/global-state'
import Portfolio from './components/portfolio.component'
import SignIn from './components/sign-in.component'
import ResourcesComponent from './components/resources.component'
import ResourceComponent from './components/resource.component'
import PortfoliosComponent from './components/portfolios.component'
import PortfolioComponent from './components/portfolio.component'
import AssetComponent from './components/asset.component'
import DocsComponent from './components/docs.component'
import DocComponent from './components/doc.component'

const App = (): JSX.Element => {
  
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem('JWT') !== null)

  return (
    <BrowserRouter>
        <Switch>
          <GlobalStateContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>

            <Route path="/" exact>{isLoggedIn ? <Home /> : <SignIn />}</Route>

            <Route path="/resource" exact>{isLoggedIn ? <ResourceComponent /> : <SignIn />}</Route>
            <Route path="/resource/:id">{isLoggedIn ? <ResourceComponent /> : <SignIn />}</Route>
            <Route path="/resources">{isLoggedIn ? <ResourcesComponent /> : <SignIn />}</Route>

            <Route path="/portfolio" exact>{isLoggedIn ? <PortfolioComponent /> : <SignIn />}</Route>
            <Route path="/portfolio/:id" exact>{isLoggedIn ? <PortfolioComponent /> : <SignIn />}</Route>
            <Route path="/portfolios">{isLoggedIn ? <PortfoliosComponent /> : <SignIn />}</Route>

            <Route path="/portfolio/:portfolioId/asset" exact>{isLoggedIn ? <AssetComponent /> : <SignIn />}</Route>
            <Route path="/portfolio/:portfolioId/asset/:assetId" exact>{isLoggedIn ? <AssetComponent /> : <SignIn />}</Route>

            <Route path="/doc" exact>{isLoggedIn ? <DocComponent /> : <SignIn />}</Route>
            <Route path="/doc/:id">{isLoggedIn ? <DocComponent /> : <SignIn />}</Route>
            <Route path="/docs">{isLoggedIn ? <DocsComponent /> : <SignIn />}</Route>




            {/* <Route path="/" exact>{ isLoggedIn ? <Home /> : <SignIn /> }</Route>

            <Route path="/blog/create" component={ Blog } />
            <Route path="/blog/update/:path" component={ Blog } />
            <Route path="/blog" component={ Blogs } />

            <Route path="/resources/create" component={ Resource } />
            <Route path="/resources/update/:_id" component={ Resource } />
            <Route path="/resources" component={ Resources } /> */}
            
            {/* <Route component={ PageNotFound } /> */}

            {/* <Route path="/portfolio" component={Portfolio} /> */}

            {/* <Route path="/doc/:id" component={Doc} /> */}
            {/* <Route path="/doc" component={Doc} /> */}
            {/* <Route path="/docs" component={Docs} /> */}
          </GlobalStateContext.Provider>
        </Switch>
    </BrowserRouter>
  )
}

export default App
