import { useFormik } from "formik"
import { Link, useHistory, useParams } from "react-router-dom"
import Template from "./template.component"
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { deleteResource, getResource, saveResource, updateResource } from "../services/resource.service";
import SpinnerComponent from "./spinner-component";
import { deleteAsset, getAsset, getPortfolioSummary, saveAsset, updateAsset } from "../services/portfolio.service";
import PortfolioSummary from '../models/portfolio-summary'

const AssetComponent = (): JSX.Element => {

  const { portfolioId, assetId } = useParams<{ portfolioId: string, assetId: string }>()
  const [isLoading, setIsLoading] = useState<boolean>(!!assetId)
  const [portfolioSummary, setPortfolioSummary] = useState<PortfolioSummary>()

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      name: '',
      ticker: '',
      urlPath: '',
      quantity: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.'),
      ticker: Yup.string().required('Ticker is required.'),
      urlPath: Yup.string().required('URL path is required.'),
      quantity: Yup.string().required('Quantity is required.')
    }),
    onSubmit: async asset => {
      setIsLoading(true)
      
      try {
        if (assetId) {
          const msg = await updateAsset(asset, portfolioId)
        } else {
          const msg = await saveAsset(asset, portfolioId)
        }
        history.push(`/portfolio/${portfolioId}`)
      } catch (e: any) {

      } finally {
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    const init = async () => {
      try {
        const portfolioSummary = await getPortfolioSummary(portfolioId)
        setPortfolioSummary(portfolioSummary)

        if (assetId) {
          const asset = await getAsset(portfolioId, assetId)
          formik.setValues(asset)
        }
      } catch (e) {

      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Asset | Gee Linsky Portal" description="Resource for Gee Linsky portal.">
      <>
        {isLoading && <SpinnerComponent />}
        {
          !isLoading &&
          <>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/portfolios">Portfolios</Link></li>
                <li className="breadcrumb-item"><Link to={`/portfolio/${portfolioSummary?._id}`}>{portfolioSummary?.name}</Link></li>
                <li className="breadcrumb-item active">{formik.values.name}</li>
              </ol>
            </nav>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.name && formik.errors.name,
                      'is-valid': formik.touched.name && !formik.errors.name
                    }
                  )}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">Ticker</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.ticker && formik.errors.ticker,
                      'is-valid': formik.touched.ticker && !formik.errors.ticker
                    }
                  )}
                  {...formik.getFieldProps('ticker')}
                />
                {formik.touched.ticker && formik.errors.ticker && <div className="invalid-feedback">{formik.errors.ticker}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">URL Path</label>
                <input
                  type="text"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.urlPath && formik.errors.urlPath,
                      'is-valid': formik.touched.urlPath && !formik.errors.urlPath
                    }
                  )}
                  {...formik.getFieldProps('urlPath')}
                />
                {formik.touched.urlPath && formik.errors.urlPath && <div className="invalid-feedback">{formik.errors.urlPath}</div>}
              </div>
              <div className="mb-3">
                <label className="form-label">Quantity</label>
                <input
                  type="number"
                  className={classNames(
                    'form-control', {
                      'is-invalid': formik.touched.quantity && formik.errors.quantity,
                      'is-valid': formik.touched.quantity && !formik.errors.quantity
                    }
                  )}
                  {...formik.getFieldProps('quantity')}
                />
                {formik.touched.quantity && formik.errors.quantity && <div className="invalid-feedback">{formik.errors.quantity}</div>}
              </div>
              <div className="d-flex align-content-start flex-wrap">
                <button type="submit" className={classNames('btn me-2', { 'btn-success': !assetId, 'btn-primary': assetId })} disabled={isLoading}>{assetId ? 'Update' : 'Create'}</button>
                {
                  assetId &&
                  <button
                    type="button"
                    className="btn btn-danger"
                    disabled={isLoading}
                    onClick={async () => {
                      const msg = await deleteAsset(portfolioId, assetId)
                      history.push(`/portfolio/${portfolioId}`)
                    }}
                  >
                    Delete
                  </button>  
                }
              </div>
            </form>
          </>
        }
      </>
    </Template>
  )
}

export default AssetComponent
