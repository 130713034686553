import Alert from "../models/alert"

type Props = {
  alert: Alert | undefined
}

const AlertComponent = ({ alert }: Props): JSX.Element => {
  return (
    <>
    {alert && <div className={`alert alert-${alert.color}`}>{alert.message}</div>}
    </>
  )
}

export default AlertComponent
