import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PortfolioSummary from "../models/portfolio-summary"
import ResourceModel from "../models/resource"
import { getPortfolios } from "../services/portfolio.service"
import { getResources } from "../services/resource.service"
import Spinner from "./spinner-component"
import Template from "./template.component"

const PortfoliosComponent = (): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [portfolios, setPortfolios] = useState<PortfolioSummary[]>([])

  useEffect((): void => {
    const init = async () => {
      try {
        const portfolios = await getPortfolios()
        setPortfolios(portfolios)
      } catch (e) {
        
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Portfolios | Gee Linsky Portal" description="Resources for Gee Linsky portal.">
     <>
      {isLoading && <Spinner />}
      {
        !isLoading &&
        <>
          <Link to="/portfolio" className="btn btn-success mb-1">Create</Link>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Asset Count</th>
                </tr>
              </thead>
              <tbody>
                {portfolios.map(portfolio => (
                  <tr key={portfolio._id}>
                    <td><Link to={`/portfolio/${portfolio._id}`}>{portfolio.name}</Link></td>
                    <td>{portfolio.assetCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      }
     </>
    </Template>
  )
}

export default PortfoliosComponent
