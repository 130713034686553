import ResourceModel from '../models/resource'
import enviroment from '../util/enviroment'
import { response } from './fetch.service'

export const getResource = (id: string): Promise<ResourceModel> => {
  const url: string = `${enviroment.apiUrl}/resource/${id}`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const getResources = (): Promise<ResourceModel[]> => {
  const url: string = `${enviroment.apiUrl}/resource`
  const options = {
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    }
  }
  return response(fetch(url, options))
}

export const saveResource = (resourceModel: ResourceModel): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/resource`
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(resourceModel)
  }
  return response(fetch(url, options))
}

export const updateResource = (resourceModel: ResourceModel): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/resource`
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
    body: JSON.stringify(resourceModel)
  }
  return response(fetch(url, options))
}

export const deleteResource = (id: string): Promise<string> => {
  const url: string = `${enviroment.apiUrl}/resource/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${ window.localStorage.getItem('JWT')}`
    },
  }
  return response(fetch(url, options))
}
